import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {handlePublishPostClick} from "./Handlers";
import {toast, ToastContainer} from "react-toastify";
import {Grid} from "@mui/material";

export const PublishPostModal = ({
  isOpen,
  onClose,
  selectedArticleId,
  selectedArticleTitle,
  selectedArticleSummary,
  wordpressImagesData
}) => {
  const [title, setTitle] = useState(selectedArticleTitle || '');
  const [summary, setSummary] = useState(selectedArticleSummary || '');
  const [selectedWordpressImageId, setSelectedWordpressImageId] = useState(null);

  useEffect(() => {
    setTitle(selectedArticleTitle || '');
    setSummary(selectedArticleSummary || '');
  }, [selectedArticleTitle, selectedArticleSummary]);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleSummaryChange = (e) => setSummary(e.target.value);

  const handlePostPublish = async () => {
    try {
      if (selectedArticleId) {
        await handlePublishPostClick(selectedArticleId, title, summary, selectedWordpressImageId);
        setSelectedWordpressImageId(null);
        onClose();
      } else {
        console.error('Something went wrong. Article not correctly selected');
        toast.error('Something went wrong. Article not correctly selected');
        setSelectedWordpressImageId(null);
        onClose();
      }
    } catch (error) {
      console.error('Error publishing news:', error);
      toast.error(`Error publishing news: ${error.message()}`);
    }
  };

   const handleWordpressImageRowClick = (newlySelectedWordpressImageId) => {
    setSelectedWordpressImageId(selectedWordpressImageId => (selectedWordpressImageId === newlySelectedWordpressImageId ? null : newlySelectedWordpressImageId));
  };


  return (
    <div>
      <ToastContainer />
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="publish-post-modal"
        aria-describedby="publish-post-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',  // 70% of the viewport width
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="publish-post-modal" variant="h6" component="h2" mb={2}>
            Publish Article
          </Typography>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            margin="normal"
            value={title}
            onChange={handleTitleChange}
            helperText={`${title.length} / 100 characters`}
            inputProps={{ maxLength: 100 }}
          />
          <TextField
            fullWidth
            label="Summary"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            value={summary}
            onChange={handleSummaryChange}
            helperText={`${summary.length} / 500 characters`}
            inputProps={{ maxLength: 500 }}
          />

          {/* Render rows in two columns */}
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            Select a Wordpress Image
          </Typography>
          <Box
            sx={{
              maxHeight: '300px',  // Fixed height (adjust as needed)
              overflowY: 'auto',   // Enable vertical scrolling
            }}
          >
            <Grid container spacing={1}>
              {wordpressImagesData.map((wordpressImage, index) => (
                <Grid item xs={12} key={wordpressImage.id}>
                  <Box
                    onClick={() => handleWordpressImageRowClick(wordpressImage.id)}
                    sx={{
                      p: 1,
                      border: '1px solid',
                      borderColor: selectedWordpressImageId === wordpressImage.id ? 'primary.main' : 'divider',
                      backgroundColor: selectedWordpressImageId === wordpressImage.id ? 'primary.main' : '',
                      borderRadius: 1,
                      cursor: 'pointer'

                    }}
                  >
                    <Typography variant="body1">{wordpressImage.id}: {wordpressImage.base_name}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handlePostPublish}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              disabled={title.trim() === '' || summary.trim() === ''}
            >
              Publish
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
