import React, { useState, useEffect, useCallback } from "react";
import DataTable from "../DataTable";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SendIcon from "@mui/icons-material/Send";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from "@mui/icons-material/Delete";
import { handleRefreshClick, handleScheduleClick, handleDeleteClick, handleDateChange, handleScheduleSubmit, handleSingleArchiveClick } from "./Handlers";
import ScheduleDialog from "./DateTimePicker";
import ArchiveMenu from "./ArchiveMenu";
import FilterMenu from "./FilterMenu";
import { PublishPostModal } from './PublishPostModal';
import baseUrl from "../shared/baseUrl";
import {toast} from "react-toastify";


const NewsTable = ({ filterCondition, showArchiveButton = true, onArchiveClick = handleSingleArchiveClick }) => {
  const [newsData, setNewsNewsData] = useState([]);
  const [wordpressImagesData, setWordpressImagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPublishPostModalOpen, setPublishPostModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedArticleData, setSelectedArticleData] = useState({ id: '', title: '', summary: '' });
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchNewsData = useCallback(async () => {
    try {
      setIsLoading(true);
      const newsResponse = await axios.get(`${baseUrl}/api/list_news/`);
      const filteredData = newsResponse.data.filter(filterCondition);
      setNewsNewsData(filteredData);
    } catch (error) {
      console.error("Error fetching news: ", error);
      toast.error('Error fetching news: ', error.message);
    } finally {
        setIsLoading(false);
    }
  }, [filterCondition]);

  useEffect(() => {
    fetchNewsData();  // This will be called everytime fetchNewsData changes, which will happen when filterCondition changes
  }, [fetchNewsData]);

  const fetchWordpressImagesData = useCallback(async () => {
    try {
      const wordpressImagesResponse = await axios.get(`${baseUrl}/api/wordpress/get_images/`);
      setWordpressImagesData(wordpressImagesResponse.data);
    } catch (error) {
      console.error("Error fetching wordpress images information: ", error);
      toast.error('Error fetching wordpress images information: ', error.message);
    }
  }, []);

  useEffect(() => {
    fetchWordpressImagesData();
  }, [fetchWordpressImagesData]);

  const handleOpenPublishPostModal = (articleId, articleTitle, articleSummary) => {
    setSelectedArticleData({ id: articleId, title: articleTitle, summary: articleSummary });
    setPublishPostModalOpen(true);
  };

  const handleClosePublishPostModal = () => {
    setPublishPostModalOpen(false);
    setSelectedArticleData({ id: '', title: '', summary: '' });
    // setFormData({}); // Reset form data if necessary
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const columns = [
    {
      field: "sentiment",
      headerName: "Sentiment",
      width: 100,
      align: "center",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => (
        <a
        href={params.row.link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline hover:text-blue-700"
      >
          {params.value}
        </a>
      ),
    },
    { field: "published", headerName: "Published", width: 100 },
    {
      field: "source",
      headerName: "Source",
    },
    { field: "category", headerName: "Category", width: 110, align: "center" },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => handleOpenPublishPostModal(params.row.id, params.row.title, params.row.summary)}
            style={{ flex: 1 }}
          >
            <SendIcon />
          </IconButton>
          <IconButton
            onClick={() => handleScheduleClick(
              { id: params.row.id, title: params.row.title, summary: params.row.summary },
              setSelectedArticleData,
              setOpen
            )}
            style={{ flex: 1 }}
          >
            <ScheduleSendIcon />
          </IconButton>
          {showArchiveButton && (
            <IconButton
              onClick={() => onArchiveClick(params.row.id, fetchNewsData)}
              style={{ flex: 1 }}
            >
              <ArchiveIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => handleDeleteClick(params.row.id, fetchNewsData)}
            style={{ flex: 1 }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = newsData.map((item, index) => ({
    id: newsData[index].id,
    sentiment: item.sentiment,
    published: item.published,
    source: item.source,
    category: item.category,
    title: item.title,
    summary: item.summary,
    link: item.link,
    scheduled: item.scheduled_time,
  }));

  return (

    <div style={{ width: "90%", margin: "0 auto", height: "90vh", overflowY: "auto" }}>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginRight: "8px" }}>
        <FilterMenu setFilterCondition={filterCondition} />
        <IconButton onClick={handleMenuClick}>
            <ArchiveIcon />
          </IconButton>
          <ArchiveMenu fetchData={fetchNewsData} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          <IconButton
            onClick={() => handleRefreshClick(fetchNewsData)}
            style={{
              fontWeight: "bold",
              fontSize: "large",
            }}
          >
            <CachedOutlinedIcon />
          </IconButton>
        </div>
        <div>
          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            autoHeight
          />
        </div>
        <PublishPostModal
          isOpen={isPublishPostModalOpen}
          onClose={handleClosePublishPostModal}
          selectedArticleId={selectedArticleData.id}
          selectedArticleTitle={selectedArticleData.title}
          selectedArticleSummary={selectedArticleData.summary}
          wordpressImagesData={wordpressImagesData}
        />
        <ScheduleDialog
          open={open}
          selectedDate={selectedDate}
          onDateChange={(date) => handleDateChange(date, setSelectedDate)}
          onCancel={() => setOpen(false)}
          onSubmit={() => handleScheduleSubmit(selectedArticleData, selectedDate, setOpen, fetchNewsData)}
        />
      </div>
  );
};

export default NewsTable;
